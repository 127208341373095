// import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import ModelList from "../../components/ModelList"
import React from "react"
import Seo from "../../components/seo"

const AcousticPage = () => {
 
  const ukulele = null;
  const classicalNylon = null;
  const acousticElectric = null;


 
  return (
    <Layout >
      <Seo title="Talman acoustic guitars" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> acoustic guitars</h1>
        
        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">coming soon...</h2>
        </section>

        <section id="acoustic-electric" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Acoustic-electric <a className="hash-link" href="#acoustic-electric">#</a>
          </h2>
          <ModelList list={acousticElectric} />
        </section>

        <section id="classical-nylon" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Classical / Nylon <a className="hash-link" href="#classical-nylon">#</a>
          </h2>
          <ModelList list={classicalNylon} />
        </section>

        <section id="ukulele" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Ukulele <a className="hash-link" href="#ukulele">#</a>
          </h2>
          <ModelList list={ukulele} />
        </section>
      </div>
    </Layout>
  )
}



export default AcousticPage